var CountryList = function () {
    return {
        get: function() {
            return [
                new Country('Afghanistan', 'AF', 'AFG', 'af').toJson(),
                new Country('Åland Islands', 'AX', 'ALA', 'ax').toJson(),
                new Country('Albania', 'AL', 'ALB', 'al').toJson(),
                new Country('Algeria', 'DZ', 'DZA', 'dz').toJson(),
                new Country('American Samoa', 'AS', 'ASM', 'as').toJson(),
                new Country('Andorra', 'AD', 'AND', 'ad').toJson(),
                new Country('Angola', 'AO', 'AGO', 'ao').toJson(),
                new Country('Anguilla', 'AI', 'AIA', 'ai').toJson(),
                new Country('Antarctica', 'AQ', 'ATA', 'aq').toJson(),
                new Country('Antigua and Barbuda', 'AG', 'ATG', 'ag').toJson(),
                new Country('Argentina', 'AR', 'ARG', 'ar').toJson(),
                new Country('Armenia', 'AM', 'ARM', 'am').toJson(),
                new Country('Aruba', 'AW', 'ABW', 'aw').toJson(),
                new Country('Australia', 'AU', 'AUS', 'au').toJson(),
                new Country('Austria', 'AT', 'AUT', 'at').toJson(),
                new Country('Azerbaijan', 'AZ', 'AZE', 'az').toJson(),
                new Country('Bahamas', 'BS', 'BHS', 'bs').toJson(),
                new Country('Bahrain', 'BH', 'BHR', 'bh').toJson(),
                new Country('Bangladesh', 'BD', 'BGD', 'bd').toJson(),
                new Country('Barbados', 'BB', 'BRB', 'bb').toJson(),
                new Country('Belarus', 'BY', 'BLR', 'by').toJson(),
                new Country('Belgium', 'BE', 'BEL', 'be').toJson(),
                new Country('Belize', 'BZ', 'BLZ', 'bz').toJson(),
                new Country('Benin', 'BJ', 'BEN', 'bj').toJson(),
                new Country('Bermuda', 'BM', 'BMU', 'bm').toJson(),
                new Country('Bhutan', 'BT', 'BTN', 'bt').toJson(),
                new Country('Bolivia', 'BO', 'BOL', 'bo').toJson(),
                new Country('Bonaire', 'BQ', 'BES', 'bq', 'nl').toJson(),
                new Country('Bosnia and Herzegovina', 'BA', 'BIH', 'ba').toJson(),
                new Country('Botswana', 'BW', 'BWA', 'bw').toJson(),
                new Country('Brazil', 'BR', 'BRA', 'br').toJson(),
                new Country('British Indian Ocean Territory', 'IO', 'IOT', 'io').toJson(),
                new Country('Brunei Darussalam', 'BN', 'BRN', 'bn').toJson(),
                new Country('Bulgaria', 'BG', 'BGR', 'bg').toJson(),
                new Country('Burkina Faso', 'BF', 'BFA', 'bf').toJson(),
                new Country('Burundi', 'BI', 'BDI', 'bi').toJson(),
                new Country('Cabo Verde', 'CV', 'CPV', 'cv').toJson(),
                new Country('Cambodia', 'KH', 'KHM', 'kh').toJson(),
                new Country('Cameroon', 'CM', 'CMR', 'cm').toJson(),
                new Country('Canada', 'CA', 'CAN', 'ca').toJson(),
                new Country('Cayman Islands', 'KY', 'CYM', 'ky').toJson(),
                new Country('Central African Republic', 'CF', 'CAF', 'cf').toJson(),
                new Country('Chad', 'TD', 'TCD', 'td').toJson(),
                new Country('Chile', 'CL', 'CHL', 'cl').toJson(),
                new Country('China', 'CN', 'CHN', 'cn').toJson(),
                new Country('Christmas Island', 'CX', 'CXR', 'cx').toJson(),
                new Country('Cocos Islands', 'CC', 'CCK', 'cc').toJson(),
                new Country('Colombia', 'CO', 'COL', 'co').toJson(),
                new Country('Comoros', 'KM', 'COM', 'km').toJson(),
                new Country('Democratic Republic of the Congo', 'CD', 'COD', 'cd').toJson(),
                new Country('Congo', 'CG', 'COG', 'cg').toJson(),
                new Country('Costa Rica', 'CR', 'CRI', 'cr').toJson(),
                new Country('Côte d\'Ivoire', 'CI', 'CIV', 'ci').toJson(),
                new Country('Croatia', 'HR', 'HRV', 'hr').toJson(),
                new Country('Curaçao', 'CW', 'CUW', 'cw').toJson(),
                new Country('Cyprus', 'CY', 'CYP', 'cy').toJson(),
                new Country('Czechia', 'CZ', 'CZE', 'cz').toJson(),
                new Country('Denmark', 'DK', 'DNK', 'dk').toJson(),
                new Country('Djibouti', 'DJ', ' DJI', 'dj').toJson(),
                new Country('Dominica', 'DM', 'DMA', 'dm').toJson(),
                new Country('Dominican Republic', 'DO', 'DOM', 'do').toJson(),
                new Country('Ecuador', 'EC', 'ECU', 'ec').toJson(),
                new Country('Egypt', 'EG', 'EGY', 'eg').toJson(),
                new Country('El Salvador', 'SV', 'SLV', 'sv').toJson(),
                new Country('Equatorial Guinea', 'GQ', 'GNQ', 'gq').toJson(),
                new Country('Eritrea', 'ER', 'ERI', 'er').toJson(),
                new Country('Estonia', 'EE', 'EST', 'ee').toJson(),
                new Country('Eswatini', 'SZ', 'SWZ', 'sz').toJson(),
                new Country('Ethiopia', 'ET', 'ETH', 'et').toJson(),
                new Country('Malvinas', 'FK', 'FLK', 'fk').toJson(),
                new Country('Faroe Islands', 'FO', 'FRO', 'fo').toJson(),
                new Country('Fiji', 'FJ', 'FJI', 'fj').toJson(),
                new Country('Finland', 'FI', 'FIN', 'fi').toJson(),
                new Country('France', 'FR', 'FRA', 'fr').toJson(),
                new Country('French Guiana', 'GF', 'GUF', 'gf').toJson(),
                new Country('French Polynesia', 'PF', 'PYF', 'pf').toJson(),
                new Country('French Southern Territories', 'TF', 'ATF', 'tf').toJson(),
                new Country('Gabon', 'GA', 'GAB', 'ga').toJson(),
                new Country('Gambia', 'GM', 'GMB', 'gm').toJson(),
                new Country('Georgia', 'GE', 'GEO', 'ge').toJson(),
                new Country('Germany', 'DE', 'DEU', 'de').toJson(),
                new Country('Ghana', 'GH', 'GHA', 'gh').toJson(),
                new Country('Gibraltar', 'GI', 'GIB', 'gi').toJson(),
                new Country('Greece', 'GR', 'GRC', 'gr').toJson(),
                new Country('Greenland', 'GL', 'GRL', 'gl').toJson(),
                new Country('Grenada', 'GD', 'GRD', 'gd').toJson(),
                new Country('Guadeloupe', 'GP', 'GLP', 'gp').toJson(),
                new Country('Guam', 'GU', 'GUM', 'gu').toJson(),
                new Country('Guatemala', 'GT', 'GTM', 'gt').toJson(),
                new Country('Guernsey', 'GG', 'GGY', 'gg').toJson(),
                new Country('Guinea', 'GN', 'GIN', 'gn').toJson(),
                new Country('Guinea-Bissau', 'GW', 'GNB', 'gw').toJson(),
                new Country('Guyana', 'GY', 'GUY', 'gy').toJson(),
                new Country('Haiti', 'HT', 'HTI', 'ht').toJson(),
                new Country('Heard Island and McDonald Islands', 'HM HMD', 'hm').toJson(),
                new Country('Vatican', 'VA', 'VAT', 'va').toJson(),
                new Country('Honduras', 'HN', 'HND', 'hn').toJson(),
                new Country('Hong Kong', 'HK', 'HKG', 'hk').toJson(),
                new Country('Hungary', 'HU', 'HUN', 'hu').toJson(),
                new Country('Iceland', 'IS', 'ISL', 'is').toJson(),
                new Country('India', 'IN', 'IND', 'in').toJson(),
                new Country('Indonesia', 'ID', 'IDN', 'id').toJson(),
                new Country('Iraq', 'IQ', 'IRQ', 'iq').toJson(),
                new Country('Ireland', 'IE', 'IRL', 'ie').toJson(),
                new Country('Isle of Man', 'IM', 'IMN', 'im').toJson(),
                new Country('Israel', 'IL', 'ISR', 'il').toJson(),
                new Country('Italy', 'IT', 'ITA', 'it').toJson(),
                new Country('Jamaica', 'JM', 'JAM', 'jm').toJson(),
                new Country('Japan', 'JP', 'JPN', 'jp').toJson(),
                new Country('Jersey', 'JE', 'JEY', 'je').toJson(),
                new Country('Jordan', 'JO', 'JOR', 'jo').toJson(),
                new Country('Kazakhstan', 'KZ', 'KAZ', 'kz').toJson(),
                new Country('Kenya', 'KE', 'KEN', 'ke').toJson(),
                new Country('Kiribati', 'KI', 'KIR', 'ki').toJson(),
                new Country('Kuwait', 'KW', 'KWT', 'kw').toJson(),
                new Country('Kyrgyzstan', 'KG', 'KGZ', 'kg').toJson(),
                new Country('Laos', 'LA', 'LAO', 'la').toJson(),
                new Country('Latvia', 'LV', 'LVA', 'lv').toJson(),
                new Country('Lebanon', 'LB', 'LBN', 'lb').toJson(),
                new Country('Lesotho', 'LS', 'LSO', 'ls').toJson(),
                new Country('Liberia', 'LR', 'LBR', 'lr').toJson(),
                new Country('Libya', 'LY', 'LBY', 'ly').toJson(),
                new Country('Liechtenstein', 'LI', 'LIE', 'li').toJson(),
                new Country('Lithuania', 'LT', 'LTU', 'lt').toJson(),
                new Country('Luxembourg', 'LU', 'LUX', 'lu').toJson(),
                new Country('Macao', 'MO', 'MAC', 'mo').toJson(),
                new Country('North Macedonia', 'MK', 'MKD', 'mk').toJson(),
                new Country('Madagascar', 'MG', 'MDG', 'mg').toJson(),
                new Country('Malawi', 'MW', 'MWI', 'mw').toJson(),
                new Country('Malaysia', 'MY', 'MYS', 'my').toJson(),
                new Country('Maldives', 'MV', 'MDV', 'mv').toJson(),
                new Country('Mali', 'ML', 'MLI', 'ml').toJson(),
                new Country('Malta', 'MT', 'MLT', 'mt').toJson(),
                new Country('Marshall Islands', 'MH', 'MHL', 'mh').toJson(),
                new Country('Martinique', 'MQ', 'MTQ', 'mq').toJson(),
                new Country('Mauritania', 'MR', 'MRT', 'mr').toJson(),
                new Country('Mauritius', 'MU', 'MUS', 'mu').toJson(),
                new Country('Mayotte', 'YT', 'MYT', 'yt').toJson(),
                new Country('Mexico', 'MX', 'MEX', 'mx').toJson(),
                new Country('Micronesia', 'FM', 'FSM', 'fm').toJson(),
                new Country('Moldova', 'MD', 'MDA', 'md').toJson(),
                new Country('Monaco', 'MC', 'MCO', 'mc').toJson(),
                new Country('Mongolia', 'MN', 'MNG', 'mn').toJson(),
                new Country('Montenegro', 'ME', 'MNE', 'me').toJson(),
                new Country('Montserrat', 'MS', 'MSR', 'ms').toJson(),
                new Country('Morocco', 'MA', 'MAR', 'ma').toJson(),
                new Country('Mozambique', 'MZ', 'MOZ', 'mz').toJson(),
                new Country('Myanmar', 'MM', 'MMR', 'mm').toJson(),
                new Country('Namibia', 'NA', 'NAM', 'na').toJson(),
                new Country('Nauru', 'NR', 'NRU', 'nr').toJson(),
                new Country('Nepal', 'NP', 'NPL', 'np').toJson(),
                new Country('Netherlands', 'NL', 'NLD', 'nl').toJson(),
                new Country('New Caledonia', 'NC', 'NCL', 'nc').toJson(),
                new Country('New Zealand', 'NZ', 'NZL', 'nz').toJson(),
                new Country('Nicaragua', 'NI', 'NIC', 'ni').toJson(),
                new Country('Niger', 'NE', 'NER', 'ne').toJson(),
                new Country('Nigeria', 'NG', 'NGA', 'ng').toJson(),
                new Country('Niue', 'NU', 'NIU', 'nu').toJson(),
                new Country('Norfolk Island', 'NF', 'NFK', 'nf').toJson(),
                new Country('Northern Mariana Islands', 'MP', 'MNP', 'mp').toJson(),
                new Country('Norway', 'NO', 'NOR', 'no').toJson(),
                new Country('Oman', 'OM', 'OMN', 'om').toJson(),
                new Country('Pakistan', 'PK', 'PAK', 'pk').toJson(),
                new Country('Palau', 'PW', 'PLW', 'pw').toJson(),
                new Country('Palestine, State of', 'PS', 'PSE', 'ps').toJson(),
                new Country('Panama', 'PA', 'PAN', 'pa').toJson(),
                new Country('Papua New Guinea', 'PG', 'PNG', 'pg').toJson(),
                new Country('Paraguay', 'PY', 'PRY', 'py').toJson(),
                new Country('Peru', 'PE', 'PER', 'pe').toJson(),
                new Country('Philippines', 'PH', 'PHL', 'ph').toJson(),
                new Country('Pitcairn', 'PN', 'PCN', 'pn').toJson(),
                new Country('Poland', 'PL', 'POL', 'pl').toJson(),
                new Country('Portugal', 'PT', 'PRT', 'pt').toJson(),
                new Country('Puerto Rico', 'PR', 'PRI', 'pr').toJson(),
                new Country('Qatar', 'QA', 'QAT', 'qa').toJson(),
                new Country('Réunion', 'RE', 'REU', 're').toJson(),
                new Country('Romania', 'RO', 'ROU', 'ro').toJson(),
                new Country('Russian Federation', 'RU', 'RUS', 'ru').toJson(),
                new Country('Rwanda', 'RW', 'RWA', 'rw').toJson(),
                new Country('Saint Barthélemy', 'BL', 'BLM', 'bl').toJson(),
                new Country('Saint Helena', 'SH', 'SHN', 'sh').toJson(),
                new Country('Saint Kitts and Nevis', 'KN', 'KNA', 'kn').toJson(),
                new Country('Saint Lucia', 'LC', 'LCA', 'lc').toJson(),
                new Country('Saint Martin', 'MF', 'MAF', 'mf').toJson(),
                new Country('Saint Pierre and Miquelon', 'PM', 'SPM', 'pm').toJson(),
                new Country('Saint Vincent and the Grenadines', 'VC', 'VCT', 'vc').toJson(),
                new Country('Samoa', 'WS', 'WSM', 'ws').toJson(),
                new Country('San Marino', 'SM', 'SMR', 'sm').toJson(),
                new Country('Sao Tome and Principe', 'ST', 'STP', 'st').toJson(),
                new Country('Saudi Arabia', 'SA', 'SAU', 'sa').toJson(),
                new Country('Senegal', 'SN', 'SEN', 'sn').toJson(),
                new Country('Serbia', 'RS', 'SRB', 'rs').toJson(),
                new Country('Seychelles', 'SC', 'SYC', 'sc').toJson(),
                new Country('Sierra Leone', 'SL', 'SLE', 'sl').toJson(),
                new Country('Singapore', 'SG', 'SGP', 'sg').toJson(),
                new Country('Sint Maarten', 'SX', 'SXM', 'sx').toJson(),
                new Country('Slovakia', 'SK', 'SVK', 'sk').toJson(),
                new Country('Slovenia', 'SI', 'SVN', 'si').toJson(),
                new Country('Solomon Islands', 'SB', 'SLB', 'sb').toJson(),
                new Country('Somalia', 'SO', 'SOM', 'so').toJson(),
                new Country('South Africa', 'ZA', 'ZAF', 'za').toJson(),
                new Country('South Georgia and the South Sandwich Islands', 'GS', 'SGS', 'gs').toJson(),
                new Country('South Korea', 'KR', 'KOR', 'kr').toJson(),
                new Country('Spain', 'ES', 'ESP', 'es').toJson(),
                new Country('Sri Lanka', 'LK', 'LKA', 'lk').toJson(),
                new Country('Suriname', 'SR', 'SUR', 'sr').toJson(),
                new Country('Svalbard Jan Mayen', 'SJ', 'SJM').toJson(),
                new Country('Sweden', 'SE', 'SWE', 'se').toJson(),
                new Country('Switzerland', 'CH', 'CHE', 'ch').toJson(),
                new Country('Taiwan', 'TW', 'TWN', 'tw').toJson(),
                new Country('Tajikistan', 'TJ', 'TJK', 'tj').toJson(),
                new Country('Tanzania, the United Republic of', 'TZ', 'TZA', 'tz').toJson(),
                new Country('Thailand', 'TH', 'THA', 'th').toJson(),
                new Country('Timor-Leste', 'TL', 'TLS', 'tl').toJson(),
                new Country('Togo', 'TG', 'TGO', 'tg').toJson(),
                new Country('Tokelau', 'TK', 'TKL', 'tk').toJson(),
                new Country('Tonga', 'TO', 'TON', 'to').toJson(),
                new Country('Trinidad and Tobago', 'TT', 'TTO', 'tt').toJson(),
                new Country('Tunisia', 'TN', 'TUN', 'tn').toJson(),
                new Country('Turkey', 'TR', 'TUR', 'tr').toJson(),
                new Country('Turkmenistan', 'TM', 'TKM', 'tm').toJson(),
                new Country('Turks and Caicos Islands', 'TC', 'TCA', 'tc').toJson(),
                new Country('Tuvalu', 'TV', 'TUV', 'tv').toJson(),
                new Country('Uganda', 'UG', 'UGA', 'ug').toJson(),
                new Country('Ukraine', 'UA', 'UKR', 'ua').toJson(),
                new Country('United Arab Emirates', 'AE', 'ARE', 'ae').toJson(),
                new Country('United Kingdom', 'GB', 'GBR', 'gb', 'uk').toJson(),
                new Country('United States', 'US', 'USA', 'us').toJson(),
                new Country('Uruguay', 'UY', 'URY', 'uy').toJson(),
                new Country('Uzbekistan', 'UZ', 'UZB', 'uz').toJson(),
                new Country('Vanuatu', 'VU', 'VUT', 'vu').toJson(),
                new Country('Venezuela', 'VE', 'VEN', 've').toJson(),
                new Country('Vietnam', 'VN', 'VNM', 'vn').toJson(),
                new Country('Virgin Islands', 'VG', 'VGB', 'vg').toJson(),
                new Country('Virgin Islands', 'VI', 'VIR', 'vi').toJson(),
                new Country('Wallis and Futuna', 'WF', 'WLF', 'wf').toJson(),
                new Country('Yemen', 'YE', 'YEM', 'ye').toJson(),
                new Country('Zambia', 'ZM', 'ZMB', 'zm').toJson(),
                new Country('Zimbabwe', 'ZW', 'ZWE', 'zw').toJson()
            ];
        }
    }
}

function getThreeCode(countryName) {
    var countryList = new CountryList().get();
    for (var i = 0; i < countryList.length; i++) {
        if (countryList[i].name == countryName) {
            return countryList[i].threeLetterIsoCode;
        }
    }
}